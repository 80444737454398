
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































































.agenda {
  width: 100%;
  border-radius: 6rem;
}

.archive-inner {
  background: $c-white;
  border-radius: 4rem 4rem 0 0;
}

.agenda__filters {
  width: auto;
  padding: 0 col(1);

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: $spacing * 1.3 col(1);
    background: $c-white;
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  }

  @include mq(m) {
    margin: 0 col(0.5, 20);
    padding: $spacing * 1.3 col(0.5, 18);
  }

  @include mq(xl) {
    margin: 0 col(1.5, 20);
    padding: $spacing * 1.3 col(0.5, 18);
  }
}

.agenda__filters__select {
  width: 100%;

  ::v-deep .form-select {
    border: 2px solid $c-black-60;
    border-radius: 6rem;
    box-shadow: none;
  }

  @include mq(m) {
    width: col(6, 18);
  }

  @include mq(l) {
    width: col(5, 18);
  }
}

.agenda__filters__switcher {
  display: flex;
  width: 100%;
  max-width: 40rem;
  margin: $spacing * 0.9 auto 0;

  @include mq(m) {
    margin: 0;
  }
}

.agenda__listing {
  margin-top: $spacing * 5;
}

.agenda__listing__title {
  margin-bottom: $spacing * 1.7;
}

.agenda__listing__list {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;

  &:last-child {
    margin-bottom: $spacing * 4.5;
  }
}

.agenda__listing__footer {
  text-align: center;
}
